<template>
  <a-modal :footer="null" :visible="visible" :closable="false" width="16rem" style="top: 40%">
    <div class="modal-progress" v-if="$store.state.curUploadProgress">
      <a-progress
        type="circle"
        :percent="
          Number.parseInt(`${($store.state.curUploadProgress.loaded / $store.state.curUploadProgress.total) * 100}`)
        "
      />
      <p></p>
      <p
        style="text-align: center"
        v-if="$store.state.curUploadProgress.loaded != $store.state.curUploadProgress.total"
      >
        上传中...
      </p>
      <p style="text-align: center" v-else><a-icon type="loading" /> 处理中...</p>
    </div>
  </a-modal>
</template>
<script lang="ts">
export default {
  components: {},
  name: 'UploadProgress',
  data() {
    return {
      visible: false,
    };
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
@import '~ant-design-vue/dist/antd.less';
.modal-progress {
  text-align: center;
}
.loading-tip {
  color: @green-6;
}
</style>
